import Vue from 'vue'
import App from './App.vue'
import createRouter from './router'
import createStore from './store'
const placeholderimage = require('@/assets/placeholder.png')
import VueLazyload from 'vue-lazyload'
import global from '@/utils/global'
import { initializeApp } from 'firebase/app'
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics'
import VueMeta from 'vue-meta'
import firebaseConfig from '../firebaseConfig' // 引入Firebase配置

Vue.prototype.$global = global

Vue.use(VueMeta)

Vue.use(VueLazyload, {
	preLoad: 1.3,
	error: placeholderimage,
	loading: placeholderimage,
	attempt: 1,
})

// 队列用于在 Analytics 初始化之前暂存事件
const eventQueue = []

// 标志是否初始化完成
let analyticsInitialized = false

// 默认的 $logEvent 和 $eventrack 方法，将请求放入队列
Vue.prototype.$logEvent = (event, params = {}) => {
	console.log(`Queued log: ${event}`, params)
	eventQueue.push({ type: 'log', event, params })
}
Vue.prototype.$eventrack = (msg, method, map = {}) => {
	console.log(`Queued track: ${msg}`, method, map)
	eventQueue.push({ type: 'track', msg, method, map })
}

function initializeFirebase() {
	const fconfig = window?.__INITIAL_STATE__?.domainConfig?.firebase || firebaseConfig
	let app = initializeApp(fconfig)
	let analytics = getAnalytics(app)
	return analytics
}

function setupAnalytics() {
	isSupported().then((result) => {
		if (result) {
			const analytics = initializeFirebase()
			logEvent(analytics, 'in_page')
			console.log('in_page')

			// 标记已初始化
			analyticsInitialized = true

			// 覆盖默认的上报方法
			Vue.prototype.$logEvent = (event, params = {}) => {
				console.log(event)
				logEvent(analytics, event, params)
			}
			Vue.prototype.$eventrack = (msg, method, map = {}) => {
				let params = {
					time: new Date(),
					message: msg,
					method: method,
					...map,
				}
				console.log(msg)
				logEvent(analytics, msg, params)
			}
			Vue.prototype.$adClick = (message) => {
				if (typeof JSCallAndroid !== 'undefined' && typeof JSCallAndroid.adClick === 'function') {
					JSCallAndroid.adClick(message)
				} else {
					console.log(message)
				}
			}
			Vue.prototype.$adPosition = (message) => {
				if (typeof JSCallAndroid !== 'undefined' && typeof JSCallAndroid.adPosition === 'function') {
					JSCallAndroid.adPosition(message)
				} else {
					console.log(message)
				}
			}

			// 处理队列中的所有请求
			while (eventQueue.length > 0) {
				const queuedEvent = eventQueue.shift()
				if (queuedEvent.type === 'log') {
					Vue.prototype.$logEvent(queuedEvent.event, queuedEvent.params)
				} else if (queuedEvent.type === 'track') {
					Vue.prototype.$eventrack(queuedEvent.msg, queuedEvent.method, queuedEvent.map)
				}
			}
		} else {
			console.log('Firebase Analytics not supported')
		}
	})
}

if (typeof window !== 'undefined') {
	setupAnalytics()
} else {
	// 服务器端只定义简单的 log
	Vue.prototype.$logEvent = (event, params = {}) => {
		console.log(`Server Log: ${event}`, params)
	}
	Vue.prototype.$eventrack = (event, params = {}) => {
		console.log(`Server Log: ${event}`, params)
	}
	Vue.prototype.$adClick = (msg) => {
		console.log(`Server Log: ${msg}`)
	}
	Vue.prototype.$adPosition = (msg) => {
		console.log(`Server Log: ${msg}`)
	}
}

export default function createApp() {
	const router = createRouter()
	const store = createStore()
	const app = new Vue({
		router,
		store,
		render: (h) => h(App),
	})
	return {
		app,
		router,
		store,
	}
}
